import React, { useCallback, useMemo, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
} from "@nextui-org/react";
import SkeletonTable from "../skeletons/SkeletonTable";

export default function StatsTabular({ users, columns }) {
  const [page, setPage] = useState(1);
  console.log(JSON.stringify(users))
  console.log(columns)

  const rowsPerPage = 20;

  const pages = Math.ceil(users.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return users.slice(start, end);
  }, [page, users]);


  const getKeyValue = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "btc_date":
        return (
          <div className="flex justify-center 2xl:justify-start">
            {new Date(cellValue).toLocaleDateString()}
          </div>
        );
      case "eth_date":
        return (
          <div className="flex justify-center 2xl:justify-start">
            {new Date(cellValue).toLocaleDateString()}
          </div>
        );
      case "btc_price":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${cellValue.toFixed(2)}
          </div>
        );
      case "eth_price":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${cellValue.toFixed(2)}
          </div>
        );
      case "avg_cost_basis_btc":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${Number(cellValue).toFixed(2)}
          </div>
        );
      case "avg_cost_basis_eth":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${Number(cellValue).toFixed(2)}
          </div>
        );
      case "unrealized_pnl_eth":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${Number(cellValue).toFixed(2)}
          </div>
        );
      case "unrealized_pnl_btc":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${Number(cellValue).toFixed(2)}
          </div>
        );
      case "portfolio_value":
        return (
          <div className="flex justify-center 2xl:justify-start">
            ${Number(cellValue).toFixed(2)}
          </div>
        );
      case "returns":
        return (
          <div className="flex justify-center 2xl:justify-start">
            {Number(cellValue).toFixed(2)} %
          </div>
        );
      case "realized_pnl_eth":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "realized_pnl_btc":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "cumulative_slippage_trade_fees":
        return (
          <div className='text-[#393939]'>
            ${Number(cellValue).toFixed(2)}
          </div>
        )
      case "slippage_trade_fees_eth":

        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "slippage_trade_fees_btc":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )

      default:
        // if(cellValue === 0){
        //  return(<div>-</div>)
        // }
        return cellValue;
    }
  }, []);

  return (
    <>
      {users.length < 1 ? <SkeletonTable /> :
        <Table
          selectionMode='single'
          className="table w-full rounded-xl"
          aria-label="Table Component"
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                classNames={{
                  wrapper: "h-4 rounded",
                  item: "h-8 text-small rounded-none bg-transparent",
                  cursor: "bg-[#393939] text-white font-bold text-[#ffff]",
                }}
                page={page}
                total={pages}
                onChange={(page) => setPage(page)}
              />
            </div>
          }
        >

          <TableHeader className="!border-b" columns={columns}>
            {(column) => (
              <TableColumn
                className="!border-b text-center !rounded-[2px] !bg-[#F9FAFB] !text-[#101828] !px-2"
              >
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody

            items={items}>
            {(item) => (
              <TableRow
                key={item.btc_date}
                className={`relative text-[#667085] text-[.8rem] cursor-pointer py-4 ${item.isGroupHeader ? 'bg-[#f7f6f3]' : ''}`}
              >
                {(columnKey) => (
                  <TableCell className="py-2 border-b text-[.8rem] text-center">
                    {getKeyValue(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>

      }

    </>
  );
}
