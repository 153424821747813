import React, { useContext, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip, Tooltip, ChipProps, Pagination } from "@nextui-org/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import OrderDetails from "./OrderDetails";
import PerformanceData from "../minerPerformance/PerformanceData";
import DailyReturnsContext from "../../context/DailyReturnsContext";

const statusColorMap = {
  Transportation: "secondary",
  Accomodation: "danger",
  Uncategorized: "default",
  Legal: "success",
  active: "success",
  Suspended: "danger",
  Paid: 'success',
  Pending: 'warning'
};


export default function TableComponent({ users, columns, selectionMode, setPerformanceData, performanceData, setPage, page }) {
  const [orderDetail, setOrderDetail] = useState(null)
  const {
    dailyReturnTableData,
    setDailyReturnTableData,
    addressSelected,
    setAddressSelected,
    setTabState,
    tabState,
    setCalculating
  } = useContext(DailyReturnsContext);

  const rowsPerPage = 20;

  const pages = Math.ceil(users.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return users.slice(start, end);
  }, [page, users]);

  const getKeyValue = React.useCallback((user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "base_currency":
        return (
          <div className="flex justify-center 2xl:justify-start">
            {cellValue.toLowerCase() === 'btc' ? <img
              alt='btc'
              className="w-[30px] h-[30px]"
              src='/assets/btc.png' /> : cellValue.toLowerCase() === 'eth' ? <img
                alt='eth'
                className="w-[30px] h-[30px]"
                src='/assets/eth.png' /> : 'Not Supported'}
          </div>
        );
      case "order_type":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "price":
        return (
          <div className='text-[#393939] font-semibold'>
            ${cellValue}
          </div>
        );
      case "miner_public_key":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.substring(0, 10)}....
          </div>
        );
      case "avg_return_thirty_days":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "avg_return":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "sharpe_ratio":
        return (
          <div className='text-[#393939] font-semibold'>
            {isNaN(cellValue) ? "0" : cellValue.toFixed(4)}
          </div>
        );
      case "calmar_ratio":
        return (
          <div className='text-[#393939] font-semibold'>
            {isNaN(cellValue) ? "0" : cellValue.toFixed(4)}
          </div>
        );
      case "active_from":
        return (
          <div className=''>
            {new Date(cellValue).toLocaleString()}
          </div>
        );
      case "last_active":
        return (
          <div className=''>
            {new Date(cellValue).toLocaleString()}
          </div>
        );
      case "position_close":
        return (
          <div className=''>
            {new Date(cellValue).getFullYear() < 2000 ? '-' : new Date(cellValue).toLocaleString()}
          </div>
        );
      case "position_open":
        return (
          <div className=''>
            {new Date(cellValue).toLocaleString()}
          </div>
        );
      case "sortino_ratio":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "total_return":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "cagr":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "max_drawdown_days":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "active_from":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "last_active":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "total_number_of_days_active":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "drawdown":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "view_more":
        return (
          <button
            onClick={() => setOrderDetail(user)}
            className='bg-[transparent] border border-[#393939] text-[#393939] w-[80px] text-center rounded-lg p-1'
          >
            View More
          </button>
        )
      case "view_details":
        return (
          <button
            onClick={() => {
              setPerformanceData(user)
              setAddressSelected(user.miner_public_key)
            }}
            className='bg-[transparent] border border-[#393939] text-[#393939] w-[50px] text-center rounded-lg p-1'
          >
            View
          </button>
        )
      default:
        return cellValue;
    }
  }, []);

  return (
    <>{items.length === 0 ? <p className="flex h-full w-full justify-center items-centertext-[1.2rem] text-red-600 font-semibold">Data Loading.....</p> : <Table
      selectionMode={selectionMode}
      className="table w-full rounded-xl"
      aria-label="Table Component"
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            classNames={{
              wrapper: "h-4 rounded",
              item: "h-8 text-small rounded-none bg-transparent",
              cursor: "bg-[#393939] text-white font-bold text-[#ffff]",
            }}
            page={page}
            total={pages}
            onChange={(page) => setPage(page)}
          />
        </div>
      }
    >

      <TableHeader
        className="!border-b"
        columns={columns}>
        {(column) => (
          <TableColumn
            className="!border-b !rounded-[2px] !bg-[#F9FAFB] !text-[#101828] !px-2"
          >
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={items}>
        {(item) => (
          <TableRow
            // onClick={() => {

            // }}
            className="relative text-[#667085] text-[.8rem] cursor-pointer py-4"
            key={item.id}
          >
            {(columnKey) => (
              <TableCell className="py-2 border-b text-[.8rem]">{getKeyValue(item, columnKey)}</TableCell>
              // <TableCell className="py-4 border-b">:</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>

    }

      {orderDetail && <OrderDetails
        orderDetail={orderDetail}
        setOrderDetail={setOrderDetail}
      />}

      {addressSelected &&
        <PerformanceData
          performanceData={performanceData}
          setPerformanceData={setPerformanceData}
          setAddressSelected={setAddressSelected}
          addressSelected={addressSelected}
        />
      }
    </>
  );
}