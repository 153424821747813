import React, { useContext, useState, useEffect } from 'react';
import DailyReturnsContext from '../../context/DailyReturnsContext';
import StatsTabular from './StatsTabular';
import { FaBackward, FaTimes } from 'react-icons/fa';

const columns = [
    { key: "btc_date", label: "Day" },
    { key: "btc_price", label: "End of Day Price(BTC)" },
    { key: "eth_price", label: "End of Day Price(ETH)" },
    { key: "returns", label: "30 day Avg return" },
    { key: "btcCummulative", label: "BTC Cummulative" },
    { key: "strategyCumulativeReturns", label: "Strategy Cumulative" },
    { key: "runningMax", label: "Running Max" },
    { key: "drawdown", label: "Drawdown" },
    { key: "strategy", label: "Strategy" },
    { key: "dDaysRunning", label: "D days Running" },
    { key: "rollingAvgDaily", label: "Rolling Average Daily" },
    { key:'stDev', label:'Standard Deviation' },
    { key:'sharpe', label:'Sharpe' }
];

function StatsTable({ statShow, setStatShow }) {
  const { dailyReturnTableData } = useContext(DailyReturnsContext);
  const [strategyData, setStrategyData] = useState([]);
  const [cagr, setCagr] = useState(null);
  const [sharpeRatio, setSharpeRatio] = useState(null);
  const [maxDrawdown, setMaxDrawdown] = useState(null);
  const [calmarRatio, setCalmarRatio] = useState(null);
  const [sortinoRatio, setSortinoRatio] = useState(null);
  const [returnsForDays, setReturnsForDays] = useState({});

  useEffect(() => {
    if (!dailyReturnTableData.length) return;

    // Prepare data for the Python API
    const apiData = dailyReturnTableData.map(({ btc_date, returns }) => ({
      date: btc_date,
      value: returns
    }));

    // Fetch metrics from Python API
    fetch('https://api.taoshidashboard.com/calculate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ returns: apiData })
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          console.error('Error:', data.error);
        } else {
          setCagr(data.cagr.toFixed(4));
          setSharpeRatio(data.sharpe.toFixed(4));
          setSortinoRatio(data.sortino.toFixed(4));
          setMaxDrawdown(data.max_drawdown.toFixed(4));
          setCalmarRatio(data.calmar.toFixed(4));
        }
      })
      .catch(error => console.error('API fetch error:', error));

    // Calculate returns for different periods (1, 7, 30, 60, 90 days)
    const calculateReturnsForPeriod = (index, days) => {
      if (index - days < 0) return null; // Not enough data for the given period
      const initialPrice = dailyReturnTableData[index - days].btc_price;
      const currentPrice = dailyReturnTableData[index].btc_price;
      return ((currentPrice - initialPrice) / initialPrice) * 100; // Return as a percentage
    };

    // Calculate returns for different periods for the most recent day
    const lastIndex = dailyReturnTableData.length - 1;
    const returnValues = {
      oneDayReturn: calculateReturnsForPeriod(lastIndex, 1)?.toFixed(2) || "N/A",
      sevenDayReturn: calculateReturnsForPeriod(lastIndex, 7)?.toFixed(2) || "N/A",
      thirtyDayReturn: calculateReturnsForPeriod(lastIndex, 30)?.toFixed(2) || "N/A",
      sixtyDayReturn: calculateReturnsForPeriod(lastIndex, 60)?.toFixed(2) || "N/A",
      ninetyDayReturn: calculateReturnsForPeriod(lastIndex, 90)?.toFixed(2) || "N/A",
    };

    setReturnsForDays(returnValues);

    // Calculate remaining data for the table
    let strategyCumulativeReturns = 1;
    let runningMax = 1;
    let dDaysRunning = 0;
    let btcCummulative = 1;

    const calculateStDev = (data) => {
      const mean = data.reduce((acc, curr) => acc + curr, 0) / data.length;
      const variance = data.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / data.length;
      return Math.sqrt(variance).toFixed(6);
    };

    const newStrategyData = dailyReturnTableData.map((entry, index) => {
      const { btc_date, btc_price, eth_price, returns } = entry;

      const dailyReturns = index === 0 ? 0 : (btc_price / dailyReturnTableData[index - 1].btc_price) - 1;
      if (index > 0) {
        btcCummulative *= (1 + dailyReturns);
      }

      strategyCumulativeReturns *= (returns + 1);
      runningMax = Math.max(strategyCumulativeReturns, runningMax);
      const drawdown = (strategyCumulativeReturns - runningMax) / runningMax;

      if (strategyCumulativeReturns < runningMax) {
        dDaysRunning += 1;
      } else {
        dDaysRunning = 0;
      }

      const strategy = (strategyCumulativeReturns - 1) * 100;

      const rollingAvgDaily = (() => {
        const availableDays = Math.min(index + 1, 180);
        const dataToAverage = dailyReturnTableData
          .slice(index - availableDays + 1, index + 1)
          .map(item => item.returns);
        const avgReturns = dataToAverage.reduce((acc, curr) => acc + curr, 0) / availableDays;
        return avgReturns.toFixed(6);
      })();

      const stDev = (() => {
        const availableDays = Math.min(index + 1, 180);
        const dataForStDev = dailyReturnTableData
          .slice(index - availableDays + 1, index + 1)
          .map(item => item.returns);

        return calculateStDev(dataForStDev);
      })();

      const sharpe = (() => {
        const rollingAvgDailyFloat = parseFloat(rollingAvgDaily);
        const stDevFloat = parseFloat(stDev);
        if (stDevFloat === 0) return 0;
        return ((rollingAvgDailyFloat * Math.sqrt(365)) / stDevFloat).toFixed(6);
      })();

      return {
        btc_date,
        btc_price,
        eth_price,
        returns,
        dailyReturns: dailyReturns.toFixed(6),
        btcCummulative: btcCummulative.toFixed(8),
        strategyCumulativeReturns: strategyCumulativeReturns.toFixed(8),
        runningMax: runningMax.toFixed(8),
        drawdown: drawdown.toFixed(8),
        strategy: strategy.toFixed(2),
        dDaysRunning,
        rollingAvgDaily,
        stDev,
        sharpe
      };
    });

    setStrategyData(newStrategyData);

  }, [dailyReturnTableData]);

  return (
    <div className='text-black'>
      <div className="mb-[30px] flex w-full items-center justify-between">
        <div className="cursor-pointer" onClick={() => {
            setStrategyData([]);
            setStatShow(false);
          }}>
          <FaBackward color="black" size={30} />
        </div>
        <h2 className='text-[1.3rem] font-semibold'>Stats</h2>
        <div className="cursor-pointer" onClick={() => {
            setStrategyData([]);
            setStatShow(false);
          }}>
          <FaTimes color="black" size={30} />
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-center">
        {/* First column - Stats */}
        <div>
          <h3 className='text-[1.3rem] font-semibold'>CAGR: {cagr}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>Sharpe Ratio: {sharpeRatio}</h3>
          <h3 className='text-[1.3rem] font-semibold'>Sortino Ratio: {sortinoRatio}</h3>
          <h3 className='text-[1.3rem] font-semibold'>Max Drawdown: {maxDrawdown}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>Calmar Ratio: {calmarRatio}</h3>
        </div>

        {/* Second column - Returns */}
        <div>
          <h3 className='text-[1.3rem] font-semibold'>1-day Return: {returnsForDays.oneDayReturn}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>7-day Return: {returnsForDays.sevenDayReturn}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>30-day Return: {returnsForDays.thirtyDayReturn}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>60-day Return: {returnsForDays.sixtyDayReturn}%</h3>
          <h3 className='text-[1.3rem] font-semibold'>90-day Return: {returnsForDays.ninetyDayReturn}%</h3>
        </div>
      </div>

      <div className='h-[400px] w-full overflow-x-auto'>
        <StatsTabular columns={columns} users={strategyData} />
      </div>
    </div>
  );
}

export default StatsTable;
