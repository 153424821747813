import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  fetchChartData,
  fetchDashboardData,
  fetchPrice,
  getTearsheet,
} from "../../functions";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import { useNavigate } from "react-router-dom";
import TableComponent from "../orders/TableComponent";
import { Spinner } from "@nextui-org/react";
import { mockDataConstants } from "../../data/mockPositionsData";
import { FaRegTimesCircle, FaTimes } from "react-icons/fa";
import SkeletonBox from "../skeletons/SkeletonBox";
import SkeletonTable from "../skeletons/SkeletonTable";
import { IoMdRefresh } from "react-icons/io";
import { toast } from "sonner";
import Modal from "../modal";
import { mockDataPositions } from './../../data/mockPositionsData'


const MinerPerformance = ({ type }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    dailyReturnTableData,
    setDailyReturnTableData,
    addressSelected,
    setAddressSelected,
    slippagePercentage, setSlippagePercentage,
    tradeFeesPercentage, setTradeFeesPercentage,
    portfolioInput, setPortfolioInput,
    multiplierInput, setMultiplierInput,
    slippageInput, setSlippageInput,
    tradeFeesInput, setTradeFeesInput,
    portfolioValueAssumption, setPortfolioValueAssumption,
    multiplierAssumption, setMultiplierAssumption,
    positionsForPerformance, setPositionsData, getMinerData
  } = useContext(DailyReturnsContext);
  // const [positionsForPerformance, setPositionsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [performanceData, setPerformanceData] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [showModal, setShowModal] = useState(false)


  const sortData = (data, key, direction = 'descending') => {
    return [...data].sort((a, b) => {
      const first = a[key];
      const second = b[key];
      let cmp = 0;

      if (typeof first === 'number' && typeof second === 'number') {
        cmp = first - second;
      } else if (typeof first === 'string' && typeof second === 'string') {
        cmp = first.localeCompare(second);
      } else if (!isNaN(Date.parse(first)) && !isNaN(Date.parse(second))) {
        cmp = new Date(first) - new Date(second);
      }
      return direction === 'descending' ? -cmp : cmp;
    });
  };

  const handleSortChange = (e) => {
    const key = e.target.value;
    setSortKey(key);
    const sortedData = sortData(tableData, key, sortDirection);
    setTableData(sortedData);
  };

  const handleDirectionChange = (e) => {
    const direction = e.target.value;
    setSortDirection(direction);
    const sortedData = sortData(tableData, sortKey, direction);
    setTableData(sortedData);
  };


  const columns = [
    {
      key: "miner_public_key",
      label: "Public key",
    },
    {
      key: "sharpe_ratio",
      label: "Sharpe",
    },
    {
      key: "sortino_ratio",
      label: "Sortino",
    },
    {
      key: "calmar_ratio",
      label: "Calmar",

    },
    {
      key: "total_return",
      label: "Total Return",

    },
    {
      key: "cagr",
      label: "CAGR",

    },
    {
      key: "drawdown",
      label: "Max drawdown",

    },
    {
      key: "max_drawdown_days",
      label: "Max drawdown days",

    },
    {
      key: "total_return",
      label: "Returns",

    },
    {
      key: "total_number_of_days_active",
      label: "Total days active",
    },
    {
      key: "active_from",
      label: "Active from",
    },
    {
      key: "last_active",
      label: "Last active",
    },
    {
      key: 'view_details',
      label: ''
    }
  ];



  // const getDashboardData = async () => {
  //   setLoading(true);
  //   const resp = await fetchDashboardData();
  //   if (resp?.data) {
  //     setPositionsData(resp.data);
  //   }
  //   setLoading(false);
  // };
  // const getMinerPerformanceData = async () => {
  //   if (positionsForPerformance) {
  //     // // Check if the specific miner's data is in the positionsForPerformance

  //     const data = Object.keys(positionsForPerformance).map((item, index) => {
  //       const filteredPositions = positionsForPerformance[item].positions.filter(
  //         (item) =>
  //           item.trade_pair.includes("ETHUSD") ||
  //           item.trade_pair.includes("BTCUSD")
  //       );

  //       if (filteredPositions.length > 0) {
  //         const avgReturn =
  //           filteredPositions.reduce(
  //             (total, current) => total + current.return_at_close,
  //             0
  //           ) / filteredPositions.length;
  //         const targetReturn = 1.01;
  //         const riskFreeRate = mockDataConstants.annual_risk_free_rate;

  //         let thirtyDayReturns = 0;
  //         let numberOfBelowThirtyDays = 0;
  //         let sumOfValuesForStandardDeviation = 0;
  //         let sumOfValuesForDownsideDeviation = 0;
  //         let numberOfNegativeReturns = 0;
  //         let troughValue = Number.POSITIVE_INFINITY;
  //         let peakValue = Number.NEGATIVE_INFINITY;
  //         let uniqueActiveDays = new Set();
  //         let lastActive = Number.NEGATIVE_INFINITY;

  //         filteredPositions.sort((a, b) => b.open_ms - a.open_ms);

  //         for (let position of filteredPositions) {
  //           const inputDateMs =
  //             position.close_ms > 0 ? position.close_ms : position.open_ms;
  //           const inputDate = moment(inputDateMs);
  //           const today = moment();
  //           const daysDifference = today.diff(inputDate, "days");

  //           if (daysDifference >= 0 && daysDifference <= 30) {
  //             thirtyDayReturns += position.return_at_close;
  //             numberOfBelowThirtyDays += 1;
  //           }

  //           sumOfValuesForStandardDeviation +=
  //             Math.pow(position.return_at_close - avgReturn, 2);

  //           sumOfValuesForDownsideDeviation +=
  //             Math.pow(Math.min(0, position.return_at_close - targetReturn), 2);

  //           if (position.return_at_close <= targetReturn) {
  //             numberOfNegativeReturns += 1;
  //           }

  //           // Add unique active days based on open_ms
  //           uniqueActiveDays.add(moment(position.open_ms).format('YYYY-MM-DD'));

  //           for (let order of position.orders) {
  //             if (order.price < troughValue) troughValue = order.price;
  //             if (order.price > peakValue) peakValue = order.price;

  //             // Update lastActive if the current processed_ms is greater
  //             if (order.processed_ms > lastActive) {
  //               lastActive = order.processed_ms;
  //             }

  //             // Add unique active days based on order processed_ms
  //             uniqueActiveDays.add(moment(order.processed_ms).format('YYYY-MM-DD'));
  //           }
  //         }

  //         const avgReturnThirtyDays =
  //           numberOfBelowThirtyDays > 0
  //             ? thirtyDayReturns / numberOfBelowThirtyDays
  //             : 0;

  //         const standardDeviation = Math.sqrt(
  //           sumOfValuesForStandardDeviation / (filteredPositions.length - 1)
  //         );
  //         const excessReturn = avgReturn - riskFreeRate;
  //         const sharpeRatio = excessReturn / standardDeviation;

  //         const downsideDeviation = Math.sqrt(
  //           sumOfValuesForDownsideDeviation / numberOfNegativeReturns
  //         );
  //         const sortinoRatio = (avgReturn - riskFreeRate) / downsideDeviation;

  //         const drawdown = (peakValue - troughValue) / peakValue;

  //         const numberOfTotalDaysActive = uniqueActiveDays.size;

  //         const numberOfConsecutiveDaysSinceToday = moment()
  //           .startOf("day")
  //           .diff(moment(lastActive).startOf("day"), "days");

  //         return {
  //           id: index + 1,
  //           miner_public_key: item,
  //           active_from: filteredPositions[filteredPositions.length - 1].open_ms,
  //           avg_return_thirty_days: avgReturnThirtyDays,
  //           avg_return: avgReturn,
  //           sharpe_ratio: sharpeRatio,
  //           sortino_ratio: sortinoRatio,
  //           drawdown,
  //           total_number_of_consecutive_days_since_today:
  //             numberOfConsecutiveDaysSinceToday,
  //           total_number_of_days_active: numberOfTotalDaysActive,
  //           last_active: lastActive // Use the latest processed_ms as last_active
  //         };
  //       } else {
  //         return {
  //           id: index + 1,
  //           miner_public_key: item,
  //           active_from: "-",
  //           avg_return_thirty_days: 0,
  //           avg_return: 0,
  //           sharpe_ratio: 0,
  //           sortino_ratio: 0,
  //           drawdown: 0,
  //           total_number_of_consecutive_days_since_today: 0,
  //           total_number_of_days_active: 0,
  //           last_active: "-"
  //         };
  //       }
  //     });

  //     setTableData(data);
  //   }
  // };

  const getMinerPerformanceData = async () => {
    if (positionsForPerformance) {
      const data = await Promise.all(
        Object.keys(positionsForPerformance)
          // .slice(0, 20) // Limit to the top 20 rows
          .map(async (item, index) => {
            const filteredPositions = positionsForPerformance[item].positions.filter(
              (position) =>
                position.trade_pair.includes("ETHUSD") || position.trade_pair.includes("BTCUSD")
            );

            if (filteredPositions.length > 0) {
              const avgReturn =
                filteredPositions.reduce((total, current) => total + current.return_at_close, 0) /
                filteredPositions.length;

              // Prepare returns data for the Python API
              const returnsData = filteredPositions.map((position) => ({
                date: new Date(position.open_ms).toISOString(), // Ensure it's an ISO string
                value: position.return_at_close,
              }));

              // console.log('Sending data to API:', JSON.stringify({ returns: returnsData }));
              try {
                const response = await fetch("https://api.taoshidashboard.com/calculate", {
                // const response = await fetch("http://localhost:5500/calculate", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ returns: returnsData }), // Stringify the body
                });

                const result = await response.json();
                if (!response.ok) throw new Error(result.error || "Failed to calculate metrics");
                console.log('API RESPONSE : ', result);

                return {
                  id: index + 1,
                  miner_public_key: item,
                  avg_return_thirty_days: avgReturn,
                  avg_return: avgReturn,
                  sharpe_ratio: result.sharpe || 0,
                  sortino_ratio: result.sortino || 0,
                  calmar_ratio: result.calmar || 0,
                  cagr: result.cagr || 0,
                  total_return: filteredPositions.reduce((total, current) => total + current.return_at_close, 0),
                  max_drawdown: result.max_drawdown || 0,
                  max_drawdown_days: result.max_drawdown_days || 0,
                  drawdown: result.max_drawdown || 0,
                  total_number_of_consecutive_days_since_today: 0,
                  total_number_of_days_active: new Set(
                    filteredPositions.map((position) => new Date(position.open_ms).toDateString())
                  ).size,
                  // active_from: filteredPositions[filteredPositions.length - 1].open_ms,
                  // last_active: filteredPositions[0].open_ms,
                  active_from: new Date(filteredPositions[filteredPositions.length - 1].open_ms).toLocaleString('en-US', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true
                  }),
                  last_active: new Date(filteredPositions[0].open_ms).toLocaleString('en-US', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true
                  }),
                };
              } catch (error) {
                console.error("Error fetching metrics from Python API:", error);
                return {
                  id: index + 1,
                  miner_public_key: item,
                  avg_return_thirty_days: 0,
                  avg_return: 0,
                  sharpe_ratio: 0,
                  sortino_ratio: 0,
                  calmar_ratio: 0,
                  cagr: 0,
                  total_return: 0,
                  max_drawdown: 0,
                  max_drawdown_days: 0,
                  drawdown: 0,
                  total_number_of_consecutive_days_since_today: 0,
                  total_number_of_days_active: 0,
                  last_active: "-",
                };
              }
            } else {
              return {
                id: index + 1,
                miner_public_key: item,
                avg_return_thirty_days: 0,
                avg_return: 0,
                sharpe_ratio: 0,
                sortino_ratio: 0,
                calmar_ratio: 0,
                cagr: 0,
                total_return: 0,
                max_drawdown: 0,
                max_drawdown_days: 0,
                drawdown: 0,
                total_number_of_consecutive_days_since_today: 0,
                total_number_of_days_active: 0,
                last_active: "-",
              };
            }
          })
      );

      setTableData(data);
    }
  };




  console.log(tableData)

  const handleSearch = () => {
    setPage(1)
    const results = tableData.filter((data) => {
      return data.miner_public_key === searchTerm
    })
    setTableData(results)
  }


  console.log(tableData)

  useEffect(() => {
    getMinerPerformanceData();
  }, []);


  const calculateWithAssumptions = () => {
    setPortfolioValueAssumption(Number(portfolioInput));
    setTradeFeesPercentage(Number(tradeFeesInput));
    setSlippagePercentage(Number(slippageInput));
    setMultiplierAssumption(Number(multiplierInput));
    // detailedOrdersTableDataSetter(addressSelected)
    setShowModal(false)
    console.log('called')
  }



  return (
    <Box>
      <div className="flex w-full items-center justify-between p-4 pb-[4px]">
        <p className="text-[1.3rem] font-bold text-[#393939]">
          Miner Performance
        </p>
        <IoMdRefresh
          onClick={() => {
            toast.success('Refreshing')
            setPositionsData(null)
            getMinerData()
            setSearchTerm('')

          }}
          color="#393939"
          size={25}
          className="cursor-pointer"
        />
      </div>


      <div className="h-[1px] w-full bg-[#D1D5DB]"></div>
      {loading ? (
        <h2>
          <Spinner color="primary" />
        </h2>
      ) : (
        <Box>
          <div className="h-fit p-4">
            <div className='w-full flex justify-between my-2 items-center'>
              <div>
                <p className='text-[#393939]'>Sort By:</p>
                <select
                  value={sortKey}
                  onChange={handleSortChange}
                  className='capitalize border p-2 rounded-lg text-[#393939]'>
                  {["Select Option", "last_active", "active_from", "avg_return_thirty_days", "avg_return", "sharpe_ratio", "sortino_ratio", "drawdown", "total_number_of_days_active"].map((item) => (
                    <option key={item} value={item}>
                      {item.replace(/_/g, " ")}
                    </option>
                  ))}
                </select>
                <select
                  value={sortDirection}
                  onChange={handleDirectionChange}
                  className='capitalize border p-2 rounded-lg text-[#393939] ml-2'>
                  {['Select Option', 'ascending', 'descending'].map((direction) => (
                    <option key={direction} value={direction}>
                      {direction}
                    </option>
                  ))}
                </select>
              </div>

              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
              >
                Assumptions
              </button>
            </div>
            {/* <div className='w-full flex items-center justify-end'>
             
                </div> */}
            <div className="flex h-fit w-full items-center gap-2">
              <div className="my-3 flex w-full items-center rounded-xl border bg-white p-2 focus:border-2 focus:border-[#393939]">

                <input
                  placeholder="Search By Address..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className=" h-[30px] w-full  text-[#393939] outline-none outline-0  transition-all delay-[.1s]"
                />
                <FaRegTimesCircle
                  onClick={() => {
                    getMinerPerformanceData()
                    setSearchTerm('')
                    if (tableData.length === 0) {
                      toast.error('Not Found')
                    }
                  }}
                  color="#393939" className="cursor-pointer" />
              </div>

              <button
                disabled={searchTerm.length < 1}
                onClick={handleSearch}
                className="rounded-md bg-[#393939] p-2 text-white disabled:opacity-[.3] disabled:cursor-not-allowed"
              >
                Search
              </button>
            </div>

            {(!positionsForPerformance) && <SkeletonTable />}

            {positionsForPerformance && <TableComponent
              users={tableData}
              columns={columns}
              page={page}
              setPage={setPage}
              setPerformanceData={setPerformanceData}
              performanceData={performanceData}
              addressSelected={addressSelected}
              setAddressSelected={setAddressSelected}
            />}
          </div>
        </Box>
      )}
      {showModal && (
        <Modal modalContainerClassName="!w-[350px]">
          <div className="mb-6 flex items-center justify-between">
            <p className="text-[1.2rem] font-semibold text-[#393939]">
              Assumptions
            </p>
            <FaTimes onClick={() => setShowModal(false)} color="#393939" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="port_val">
              <p className="text-[#393939]">Portfolio Value:</p>
              <input
                type="number"
                value={portfolioInput}
                onChange={(e) => setPortfolioInput(e.target.value)}
                id="port_val"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="oz">
              <p className="mt-4 text-[#393939]">Order Size Multiplier:</p>
              <input
                type="number"
                value={multiplierInput}
                onChange={(e) => setMultiplierInput(e.target.value)}
                id="oz"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="slippage">
              <p className="mt-4 text-[#393939]">Slippage(% of trade size):</p>
              <input
                type="number"
                value={slippageInput}
                onChange={(e) => setSlippageInput(e.target.value)}
                id="slippage"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="trade">
              <p className="mt-4 text-[#393939]">
                Trade Fees: (% of trade size)
              </p>
              <input
                type="number"
                value={tradeFeesInput}
                onChange={(e) => setTradeFeesInput(e.target.value)}
                id="trade"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>

            <button
              onClick={calculateWithAssumptions}
              className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
            >
              Update Table
            </button>
          </div>
        </Modal>
      )}
    </Box>
  );
};

export default MinerPerformance;
