import React, { useContext, useEffect, useState } from "react";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import { Box, Typography } from "@mui/material";
import { getTearsheet } from "../../functions";
import { jelly } from 'ldrs'

jelly.register()

function Tearsheet() {
  const { dailyReturnTableData, setDailyReturnTableData } =
    useContext(DailyReturnsContext);
  const [loading, setLoading] = useState(false);
  const [tearsheetFileString, setTearsheetFileString] = useState("");

  const fetchTearsheet = async () => {
    setLoading(true);
    const dailyReturnData = [...dailyReturnTableData];
    setDailyReturnTableData([]);
    const tearsheetData = await getTearsheet(dailyReturnData);
    console.log('tearsheetData>>>>>>>>>>>',tearsheetData.data.data);
    
    setTearsheetFileString(tearsheetData.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (dailyReturnTableData.length > 0) fetchTearsheet();
  }, [dailyReturnTableData]);

  return (
    <div className='h-screen'>
      {loading ? (
        <div className="flex flex-col h-screen w-full items-center justify-center ">
          <l-jelly size="40" speed="0.9" color="black"></l-jelly>
          <p>Generating Tearsheet...</p>
        </div>
      ) : tearsheetFileString ? (
        <Box mt="30px">
          <div dangerouslySetInnerHTML={{ __html: tearsheetFileString }}></div>
        </Box>
      ) : (
        <Typography variant="h4">No data</Typography>
      )}
    </div>
  );
}

export default Tearsheet;
