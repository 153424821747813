import React, { useEffect, useState } from "react";
import DailyReturnsContext from "./DailyReturnsContext";
import { toast } from "sonner";

const DailyReturnsProvider = ({ children }) => {
  const [dailyReturnTableData, setDailyReturnTableData] = useState([]);
  const [addressSelected, setAddressSelected] = useState("");
  const [btcPriceList, setBtcPriceList] = useState([]);
  const [ethPriceList, setEthPriceList] = useState([]);
  const [positionsData, setPositionsData] = useState(null);
  const [positionsForOrders, setPositionsForOrders] = useState(null);
  const [positionsForPerformance, setPositionsForPerformance] = useState(null);
  const today = Date.now();
  const [value, setValue] = React.useState(0);
  const [slippagePercentage, setSlippagePercentage] = useState(0.09);
  const [tradeFeesPercentage, setTradeFeesPercentage] = useState(0.06);
  const [portfolioInput, setPortfolioInput] = useState("10000");
  const [multiplierInput, setMultiplierInput] = useState("3");
  const [slippageInput, setSlippageInput] = useState("0.09");
  const [tradeFeesInput, setTradeFeesInput] = useState("0.06");
  const [portfolioValueAssumption, setPortfolioValueAssumption] =
    useState(10000);
  const [multiplierAssumption, setMultiplierAssumption] = useState(3);
  const [tabState, setTabState] = useState(0);
  const [detailedOrdersTableData, setDetailedOrdersTableData] = useState([]);
  const [ethOrders, setEthOrders] = useState([]);
  const [calculating, setCalculating] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [statData, setStatData] = useState([]);
  const [pieChartData, setPieChartDataData] = useState([]);
    
  const [showSidebar, setShowSidebar] = useState(true);

  console.log(today)

  const getMinerData=()=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch('https://backend.taoshidashboard.com/api/positions', requestOptions)
      .then(response => response.json())
      .then(data => {
        setPositionsData(data.data.positions)
        setPositionsForOrders(data.data.positions)
        setPositionsForPerformance(data.data.positions)
        console.log(JSON.stringify(data.data))
        console.log('fetched')
      })
      .catch(error => {
        toast.error('Could not reach miner data please refresh')
        console.log('error', error)
      });
  }

  const getBtcPrices=()=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-cg-pro-api-key", "CG-19YdQo5eZ7oFhb1qJFLwLxqo")

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`https://pro-api.coingecko.com/api/v3/coins/bitcoin/market_chart/range?vs_currency=usd&from=1711065600&to=${today}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setBtcPriceList(data.prices)
        console.log(data)
        console.log('fetched')
      })
      .catch(error => {
        toast.error('Could not reach btc data please refresh')
        console.log('error', error)
      });
  }
  const getEthPrices=()=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-cg-pro-api-key", "CG-19YdQo5eZ7oFhb1qJFLwLxqo")

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`https://pro-api.coingecko.com/api/v3/coins/ethereum/market_chart/range?vs_currency=usd&from=1711065600&to=${today}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setEthPriceList(data.prices)
        console.log(data)
        console.log('fetched')
      })
      .catch(error => {
        toast.error('Could not reach eth data please refresh')
        console.log('error', error)
      });
  }

  const getDetailedOrders = (address) => {
    const detailedOrdersData = [];
    const formatValues = (value) => (value > 1 ? parseInt(value) : value);
  
    const filteredPositions = positionsData[address].positions.filter(
      (item) => item.trade_pair.includes("BTCUSD")
    );
  
    if (filteredPositions.length > 0) {
      const portfolioValueMap = new Map();
      dailyReturnTableData.forEach((dailyData) => {
        const date = new Date(dailyData.btc_date);
        date.setUTCHours(0, 0, 0, 0);
        portfolioValueMap.set(date.getTime(), dailyData.portfolio_value);
      });
  
      let orderSizeUsd;
      let orderSizeAsset;
      let orderId = 0;
      const holdings = { ETH: 0, BTC: 0 };
      const realizedPnl = { ETH: 0, BTC: 0 };
      const totalPositionSize = { ETH: 0, BTC: 0 };
      const averageFillPrice = { ETH: 0, BTC: 0 };
      let unrealizedPnlValueEth = 0;
      let unrealizedPnlValueBtc = 0;
      let netLeverageEth = 0;
      let netLeverageBtc = 0;
      let dailyReturns = 0;
      let unrealizedPnlVal = 0;
      let portfolioValue = portfolioValueAssumption;
      let prevDate;
      let cumulativeSlippageTradeFees = 0;
      let afterFlat = false;
      const initialOrderType = {};
      const initialAvgCostBasis = {};
  
      // Set portfolio value based on earliest matching date from dailyReturnTableData
      const earliestOrderDate = new Date(Math.min(...filteredPositions.map(pos => new Date(pos.orders[0].processed_ms))));
      const correspondingDailyReturn = dailyReturnTableData.find(
        (dailyData) => new Date(dailyData.btc_date).setUTCHours(0, 0, 0, 0) === earliestOrderDate.setUTCHours(0, 0, 0, 0)
      );
      portfolioValue = correspondingDailyReturn ? correspondingDailyReturn.portfolio_value : portfolioValueAssumption;
  
      // Flatten orders from all positions and mark them with position_number
      let orders = filteredPositions.flatMap((position, index) => {
        const data = position.orders.map((order) => ({
          ...order,
          position_number: index,
          asset: position.trade_pair.includes("BTCUSD") ? "BTC" : "ETH",
        }));
        return data;
      });
      console.log(orders)
  
      // Sort orders by processed time
      orders.sort((a, b) => a.processed_ms - b.processed_ms);
  
      // Identify the last order for each position_number
      const lastOrderForPosition = {};
      orders.forEach((order) => {
        lastOrderForPosition[order.position_number] = order.processed_ms;
      });
  
      // Process orders
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const {
          asset,
          leverage,
          order_type,
          price,
          processed_ms,
          position_number,
        } = order;
  
        const isLastOrderInPosition = (i === orders.length - 1 || orders[i + 1].position_number !== position_number);
        const isFollowedByAnotherTrade = (i < orders.length - 1 && orders[i + 1].position_number > position_number);
  
        // Check if this is the first order of the position
        if (!initialOrderType[position_number]) {
          initialOrderType[position_number] = order_type;
          initialAvgCostBasis[position_number] = 0;
        }


        let prevTotalPositionSize = totalPositionSize[asset];
  
        // Treat the last order as FLAT, regardless of its actual order_type
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          afterFlat = true;
        } else if (afterFlat) {
          const processedDate = new Date(processed_ms);
          processedDate.setUTCHours(0, 0, 0, 0);
  
          const correspondingDailyReturn = dailyReturnTableData.find((dailyData) => {
            const dailyDate = new Date(dailyData.btc_date);
            dailyDate.setUTCHours(0, 0, 0, 0);
            return dailyDate.getTime() === processedDate.getTime();
          });
  
          if (correspondingDailyReturn) {
            portfolioValue = correspondingDailyReturn.portfolio_value;
            afterFlat = false;
          }
        }
  
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          orderSizeAsset = -totalPositionSize[asset];
          orderSizeUsd = orderSizeAsset * price;
          averageFillPrice[asset] = 0;
        } else {
          orderSizeUsd = leverage * portfolioValue * Number(multiplierAssumption);
          orderSizeAsset = orderSizeUsd / price;
        }
  
        let avgCostBasisAfterOrder;
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          orderSizeAsset = -totalPositionSize[asset];
          avgCostBasisAfterOrder = 0;
        } else if (order_type === initialOrderType[position_number]) {
          avgCostBasisAfterOrder =
            (initialAvgCostBasis[position_number] * totalPositionSize[asset] +
              orderSizeAsset * price) /
            (totalPositionSize[asset] + orderSizeAsset);
        } else {
          avgCostBasisAfterOrder = initialAvgCostBasis[position_number];
        }
  
        holdings[asset] += orderSizeAsset;
        totalPositionSize[asset] += orderSizeAsset;
  
        if (totalPositionSize[asset] !== 0) {
          averageFillPrice[asset] =
            ((totalPositionSize[asset] - orderSizeAsset) *
              averageFillPrice[asset] +
              price * orderSizeAsset) /
            totalPositionSize[asset];
        } else {
          averageFillPrice[asset] = 0;
        }
  
        const slippageTradeFees = -Math.abs(
          orderSizeUsd * (Number(slippagePercentage) / 100 + Number(tradeFeesPercentage) / 100)
        );
        cumulativeSlippageTradeFees += slippageTradeFees;
  
        // Realized PnL calculation
        if (order_type !== initialOrderType[position_number]) {
          realizedPnl[asset] +=
            (initialAvgCostBasis[position_number] - price) * orderSizeAsset;
        }
  
        if (order_type !== 'FLAT' && !(isLastOrderInPosition && isFollowedByAnotherTrade)) {
          initialAvgCostBasis[position_number] = avgCostBasisAfterOrder;
        }
  
        let returnValue = portfolioValue / portfolioValue - 1;
        returnValue = returnValue * 100;
  
        const processedTime = new Date(processed_ms);
        const currentData = processedTime.toLocaleDateString("en-US");
  
        if (currentData === prevDate) {
          dailyReturns += returnValue;
        } else {
          dailyReturns = returnValue;
        }
  
        prevDate = currentData;
  
        unrealizedPnlVal =
          (price - initialAvgCostBasis[position_number]) *
          totalPositionSize[asset];
  
        const calculatedData = {
          id: ++orderId,
          leverage: leverage.toFixed(5),
          order_type: order_type,
          asset,
          processed_time: processedTime.toLocaleString("en-US", {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit',
            hour12: true
          }),
          date: processedTime,
          position_id: position_number + 1,
          price: price,
          order_size_usd: formatValues(orderSizeUsd).toFixed(2),
          order_size_asset: orderSizeAsset,
          total_position_size_eth: totalPositionSize["ETH"],
          total_position_size_btc: totalPositionSize["BTC"],
          market_value_eth:
            asset === "ETH"
              ? formatValues(totalPositionSize["ETH"]) * price
              : 0,
          market_value_btc:
            asset === "BTC"
              ? formatValues(totalPositionSize["BTC"]) * price
              : 0,
          avg_cost_basis: avgCostBasisAfterOrder.toFixed(2),
          cost_basis_eth: formatValues(totalPositionSize["ETH"] * averageFillPrice["ETH"]),
          cost_basis_btc: formatValues(totalPositionSize["BTC"] * averageFillPrice["BTC"]),
          unrealizedPnlValueEth,
          unrealizedPnlValueBtc,
          realized_pnl_eth: realizedPnl["ETH"].toFixed(2),
          realized_pnl_btc: realizedPnl["BTC"].toFixed(2),
          portfolio_value: Number(portfolioValue).toFixed(2),
          daily_returns: dailyReturns,
          net_leverage_eth: netLeverageEth,
          net_leverage_btc: netLeverageBtc,
          realized_pnl: realizedPnl[asset].toFixed(2),
          unrealized_pnl: unrealizedPnlVal.toFixed(2),
          slippage_trade_fees: slippageTradeFees.toFixed(2),
          cumulative_slippage_trade_fees: cumulativeSlippageTradeFees.toFixed(2),
        };
  
        detailedOrdersData.push(calculatedData);
      }
    }
  
    return {
      detailedOrdersData,
    };
  };
  
  

  const getEthDetailedOrders = (address) => {
    const detailedOrdersEthData = [];
  
    const formatValues = (value) => {
      if (value > 1) return parseInt(value);
      else return value;
    };
  
    const filteredPositions = positionsData[address].positions.filter(
      (item) => item.trade_pair.includes("ETHUSD")
    );
  
    if (filteredPositions.length > 0) {
      const portfolioValueMap = new Map();
      dailyReturnTableData.forEach((dailyData) => {
        const date = new Date(dailyData.btc_date);
        date.setUTCHours(0, 0, 0, 0);
        portfolioValueMap.set(date.getTime(), dailyData.portfolio_value);
      });
      let orderId = 0;
  
      const holdings = { ETH: 0, BTC: 0 };
      const realizedPnl = { ETH: 0, BTC: 0 };
      const totalPositionSize = { ETH: 0, BTC: 0 };
      const averageFillPrice = { ETH: 0, BTC: 0 };
      let unrealizedPnlValueEth = 0;
      let unrealizedPnlValueBtc = 0;
      let netLeverageEth = 0;
      let netLeverageBtc = 0;
      let dailyReturns = 0;
      let slippageTradeFees;
      let unrealizedPnlVal = 0;
      // Calculate order size in USD and asset units
      let orderSizeUsd;
      let orderSizeAsset;
      let portfolioValue = portfolioValueAssumption;
      let cumulativeSlippageTradeFees = 0; // Initialize cumulative slippage and trade fees
      let prevDate;
      const initialOrderType = {}; // To track the first order type for each position
      const initialAvgCostBasis = {}; // To track the initial average cost basis for each position
      let afterFlat = false;
  
      // Set portfolio value based on earliest matching date from dailyReturnTableData
      const earliestOrderDate = new Date(
        Math.min(
          ...filteredPositions.map((pos) => new Date(pos.orders[0].processed_ms))
        )
      );
      const correspondingDailyReturn = dailyReturnTableData.find(
        (dailyData) =>
          new Date(dailyData.btc_date).setUTCHours(0, 0, 0, 0) ===
          earliestOrderDate.setUTCHours(0, 0, 0, 0)
      );
  
      portfolioValue = correspondingDailyReturn
        ? correspondingDailyReturn.portfolio_value
        : portfolioValueAssumption;
  
      // Extract all orders
      let orders = filteredPositions.flatMap((position, index) => {
        const data = position.orders.map((order) => ({
          ...order,
          position_number: index,
          asset: position.trade_pair.includes("BTCUSD") ? "BTC" : "ETH",
        }));
        return data;
      });
  
      console.log(orders);
  
      // Sort orders by processed_ms
      orders.sort((a, b) => a.processed_ms - b.processed_ms);

       // Identify the last order for each position_number
      const lastOrderForPosition = {};
      orders.forEach((order) => {
        lastOrderForPosition[order.position_number] = order.processed_ms;
      });
  
      console.log(orders);
  
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const {
          asset,
          leverage,
          order_type,
          price,
          processed_ms,
          position_number,
        } = order;

        const isLastOrderInPosition = (i === orders.length - 1 || orders[i + 1].position_number !== position_number);
        const isFollowedByAnotherTrade = (i < orders.length - 1 && orders[i + 1].position_number > position_number);
  
  
        if (!initialOrderType[position_number]) {
          initialOrderType[position_number] = order_type;
          initialAvgCostBasis[position_number] = 0;
        }
  
        let prevTotalPositionSize = totalPositionSize[asset];
  
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          afterFlat = true;
        } else if (afterFlat) {
          const processedDate = new Date(processed_ms);
          processedDate.setUTCHours(0, 0, 0, 0);
          const correspondingDailyReturn = dailyReturnTableData.find(
            (dailyData) => {
              const dailyDate = new Date(dailyData.btc_date);
              dailyDate.setUTCHours(0, 0, 0, 0);
              return dailyDate.getTime() === processedDate.getTime();
            }
          );
  
          if (correspondingDailyReturn) {
            portfolioValue = correspondingDailyReturn.portfolio_value;
            afterFlat = false;
          }
        }
  
  
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          // When the order is FLAT, calculate orderSizeAsset based on the total position size
          orderSizeAsset = -totalPositionSize[asset];
          orderSizeUsd = orderSizeAsset * price; // Calculate order size in USD using current price
          averageFillPrice[asset] = 0; // Avg fill price should be reset to 0 when FLAT
        } else {
          orderSizeUsd = leverage * portfolioValue * Number(multiplierAssumption);
          orderSizeAsset = orderSizeUsd / price;
  
          // if (order_type === initialOrderType[position_number]) {
          //   initialAvgCostBasis[position_number] =
          //     (initialAvgCostBasis[position_number] * totalPositionSize[asset] +
          //       orderSizeAsset * price) /
          //     (totalPositionSize[asset] + orderSizeAsset);
          // }
        }
  
        // Calculate avgCostBasisAfterOrder based on initial order type comparison
        let avgCostBasisAfterOrder;
        if (order_type === "FLAT" || (isLastOrderInPosition && isFollowedByAnotherTrade)) {
          // When the order is FLAT, set orderSizeAsset to the negative of the previous total position size
          orderSizeAsset = -totalPositionSize[asset];
  
          avgCostBasisAfterOrder = 0; // Avg cost basis should be reset to 0 when FLAT
        } else if (order_type === initialOrderType[position_number]) {
          avgCostBasisAfterOrder =
            (initialAvgCostBasis[position_number] * totalPositionSize[asset] +
              orderSizeAsset * price) /
            (prevTotalPositionSize + orderSizeAsset);
        } else {
          avgCostBasisAfterOrder = initialAvgCostBasis[position_number];
        }
  
       
  
        holdings[asset] += orderSizeAsset; // (negative for short)
        totalPositionSize[asset] += orderSizeAsset;
  
        // if (totalPositionSize[asset] !== 0) {
        //   averageFillPrice[asset] =
        //     ((totalPositionSize[asset] - orderSizeAsset) *
        //       averageFillPrice[asset] +
        //       price * orderSizeAsset) /
        //     totalPositionSize[asset];
        // } else {
        //   averageFillPrice[asset] = 0;
        // }
  
        let slippageTradeFees = -Math.abs(
          orderSizeUsd *
            (Number(slippagePercentage) / 100 + Number(tradeFeesPercentage) / 100)
        );
  
        // Accumulate the slippage and trade fees
        cumulativeSlippageTradeFees += slippageTradeFees;
        // let marketValue = totalPositionSize[asset] * price;
  
        // let costBasis = totalPositionSize[asset] * averageFillPrice[asset];
  
        // Calculate realizedPnL
        if (order_type !== initialOrderType[position_number]) {
          realizedPnl[asset] +=
            (initialAvgCostBasis[position_number] - price) * orderSizeAsset;
        }
  
        
        if (order_type !== 'FLAT' && !(isLastOrderInPosition && isFollowedByAnotherTrade)) {
          initialAvgCostBasis[position_number] = avgCostBasisAfterOrder;
          }
        // Calculate overall portfolio metrics
        // unrealizedPnlValueEth += asset === "ETH" ? unrealizedPnlValue : 0;
        // unrealizedPnlValueBtc += asset === "BTC" ? unrealizedPnlValue : 0;
  
        // let portfolioValue =
        //   initialPortfolioValue +
        //   realizedPnl["ETH"] +
        //   realizedPnl["BTC"] +
        //   unrealizedPnlValueEth +
        //   unrealizedPnlValueBtc;
  
        let returnValue = portfolioValue / portfolioValue - 1;
        returnValue = returnValue * 100;
  
        const processedTime = new Date(processed_ms);
        const currentData = processedTime.toLocaleDateString("en-US");
  
        if (currentData === prevDate) {
          dailyReturns += returnValue;
        } else {
          dailyReturns = returnValue;
        }
  
        prevDate = currentData;
  
        unrealizedPnlVal =
        (price - initialAvgCostBasis[position_number]) *
        totalPositionSize[asset];
        // prevDate = currentData;
  
  
        const calculatedData = {
          id: ++orderId,
          leverage: leverage.toFixed(5),
          order_type: order_type,
          asset,
          processed_time: processedTime.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          }),
          date: processedTime,
          position_id: position_number + 1,
          price: price,
          order_size_usd: formatValues(orderSizeUsd).toFixed(2),
          order_size_asset: orderSizeAsset,
          total_position_size_eth: totalPositionSize["ETH"],
          total_position_size_btc: totalPositionSize["BTC"],
          market_value_eth:
            asset === "ETH" ? formatValues(totalPositionSize["ETH"]) * price : 0,
          market_value_btc:
            asset === "BTC" ? formatValues(totalPositionSize["BTC"]) * price : 0,
          avg_cost_basis: avgCostBasisAfterOrder.toFixed(2),
          cost_basis_eth: formatValues(
            totalPositionSize["ETH"] * averageFillPrice["ETH"]
          ),
          cost_basis_btc: formatValues(
            totalPositionSize["BTC"] * averageFillPrice["BTC"]
          ),
          unrealizedPnlValueEth,
          unrealizedPnlValueBtc,
          realized_pnl_eth: realizedPnl["ETH"].toFixed(2),
          realized_pnl_btc: realizedPnl["BTC"].toFixed(2),
          portfolio_value:Number(portfolioValue).toFixed(2),
          return_value: `${returnValue.toFixed(6)}%`,
          daily_returns: dailyReturns,
          net_leverage_eth: netLeverageEth,
          net_leverage_btc: netLeverageBtc,
          realized_pnl: realizedPnl[asset].toFixed(2),
          unrealized_pnl: unrealizedPnlVal.toFixed(2),
          slippage_trade_fees: slippageTradeFees.toFixed(2),
          cumulative_slippage_trade_fees: cumulativeSlippageTradeFees.toFixed(2), // Add cumulative slippage and trade fees
        };
        detailedOrdersEthData.push(calculatedData);
      }
    }
  
    return {
      detailedOrdersEthData,
    };
  };
  
  
  const getAllOrdersData=(address)=>{
      const allOrdersData = [];
  
      const formatValues = (value) => {
        if (value > 1) return parseInt(value);
        else return value;
      };
  
      const filteredPositions = positionsData[address].positions.filter(
        (item) => item.trade_pair.includes("BTCUSD") || item.trade_pair.includes("ETHUSD")
      );
  
      if (filteredPositions.length > 0) {
        const initialPortfolioValue = 100000;
        let orderId = 0;
  
  
        // Extract all orders
        let orders = filteredPositions.flatMap((position, index) => {
          const data = position.orders.map((order) => ({
            ...order,
            position_number: index,
            asset: position.trade_pair.includes("BTCUSD") ? "BTC" : "ETH",
          }));
          return data;
        });
  
        // Sort orders by processed_ms
        orders.sort((a, b) => a.processed_ms - b.processed_ms);
  
        for (let order of orders) {
          const {
            asset,
            leverage,
            order_type,
            price,
            processed_ms,
            position_number,
          } = order;
  
         
  
          const calculatedData = {
            id: ++orderId,
            leverage: leverage,
            order_type: order_type,
            asset,
            processed_time: processed_ms,
            position_id: position_number + 1,
            price: price,
            
          };
  
          allOrdersData.push(calculatedData);
        }
      }
  
      return {
        allOrdersData,
      };
  }
  
  const detailedOrdersTableDataSetter = async (address) => {
    // setDetailedOrdersLoading(true);
    setCalculating(true)

    const { detailedOrdersData } = getDetailedOrders(address);
    const { detailedOrdersEthData } = getEthDetailedOrders(address);
    const { allOrdersData } = getAllOrdersData(address);

    // Initialize returnsCombined with corresponding btc and eth data
    let returnsCombined = btcPriceList.map((btcItem, index) => {
      const ethItem = ethPriceList[index];
      const btcDate = new Date(btcItem[0]);
      const ethDate = new Date(ethItem[0]);

      btcDate.setUTCHours(0, 0, 0, 0);
      ethDate.setUTCHours(0, 0, 0, 0);
        return {
          btc_date: btcDate,
          btc_price: btcItem[1],
          // eth_date: ethDate,
          eth_price: ethItem[1],
            total_position_size_btc: 0,
            total_position_size_eth: 0,
            avg_cost_basis_btc: 0,
            avg_cost_basis_eth: 0,
            realized_pnl_btc: 0,
            realized_pnl_eth: 0,
            unrealized_pnl_btc: 0,
            unrealized_pnl_eth: 0,
            slippage_trade_fees_btc: 0,
            slippage_trade_fees_eth: 0,
            cumulative_slippage_trade_fees: 0,
            portfolio_value: portfolioValueAssumption,  // Set initial portfolio value
            returns: 0, // Initialize returns
        };
    });

    const allOrders = [...detailedOrdersData, ...detailedOrdersEthData];
    const earliestOrderDate = new Date(Math.min(...allOrders.map(order => order.date.getTime())));
    earliestOrderDate.setDate(earliestOrderDate.getDate() - 1);

     // Filter returnsCombined to start from the day before the earliest order date
returnsCombined = returnsCombined.filter(item => item.btc_date >= earliestOrderDate);


const addExtractedValuesToReturns = (orders, returnsData, asset) => {
  let lastMatchingOrder = null;
  let previousPortfolioValue = returnsData[0].portfolio_value;   // Start with initialPortfolioValue

  returnsData.forEach((item, index) => {
    const orderDate = item.btc_date.toLocaleDateString("en-US");
    
    // Get 11:59 PM UTC of the current date
    const endOfDayUTC = new Date(item.btc_date);
    endOfDayUTC.setUTCHours(23, 59, 0, 0);  // Set the time to 11:59:00 PM UTC

    // Filter the orders to only those on the current date and before 11:59 PM UTC
    const matchingOrders = orders.filter((order) => {
      const orderDateUTC = new Date(order.date);
      return orderDateUTC.toLocaleDateString("en-US") === orderDate && orderDateUTC <= endOfDayUTC;
    });

    // If we have matching orders for the day, pick the closest to 11:59 PM UTC
    if (matchingOrders.length > 0) {
      lastMatchingOrder = matchingOrders.reduce((closestOrder, currentOrder) => {
        const closestOrderUTC = new Date(closestOrder.date);
        const currentOrderUTC = new Date(currentOrder.date);
        
        // Return the order that is closest to 11:59 PM UTC
        return Math.abs(endOfDayUTC - currentOrderUTC) < Math.abs(endOfDayUTC - closestOrderUTC)
          ? currentOrder
          : closestOrder;
      });
    }

    // If a matching order was found, populate the relevant values
    if (lastMatchingOrder) {
      if (asset === 'btc') {
        item.avg_cost_basis_btc = parseFloat(lastMatchingOrder.avg_cost_basis);
        item.realized_pnl_btc = parseFloat(lastMatchingOrder.realized_pnl);
        item.slippage_trade_fees_btc = parseFloat(lastMatchingOrder.cumulative_slippage_trade_fees);
        item.total_position_size_btc = lastMatchingOrder.total_position_size_btc;
        // Calculate unrealized PnL for BTC
        item.unrealized_pnl_btc = (item.btc_price - item.avg_cost_basis_btc) * item.total_position_size_btc;
      } else if (asset === 'eth') {
        item.avg_cost_basis_eth = parseFloat(lastMatchingOrder.avg_cost_basis);
        item.realized_pnl_eth = parseFloat(lastMatchingOrder.realized_pnl);
        item.slippage_trade_fees_eth = parseFloat(lastMatchingOrder.cumulative_slippage_trade_fees);
        item.total_position_size_eth = parseFloat(lastMatchingOrder.total_position_size_eth);
        // Calculate unrealized PnL for ETH
        item.unrealized_pnl_eth = (item.eth_price - item.avg_cost_basis_eth) * item.total_position_size_eth;
      }
    }

    // Calculate cumulative slippage trade fees and portfolio value
    item.cumulative_slippage_trade_fees = item.slippage_trade_fees_btc + item.slippage_trade_fees_eth;

    if (index > 0) {
      item.portfolio_value = portfolioValueAssumption
        + item.realized_pnl_btc
        + item.realized_pnl_eth
        + item.unrealized_pnl_btc
        + item.unrealized_pnl_eth
        + item.cumulative_slippage_trade_fees;

      item.returns = ((item.portfolio_value / previousPortfolioValue) - 1) * 100;
      previousPortfolioValue = item.portfolio_value;
    }
  });
};

    

    // Add extracted values from both BTC and ETH detailed orders to returnsCombined
    addExtractedValuesToReturns(detailedOrdersData, returnsCombined, 'btc');
    addExtractedValuesToReturns(detailedOrdersEthData, returnsCombined, 'eth');

    setDetailedOrdersTableData(detailedOrdersData);
    setEthOrders(detailedOrdersEthData);
    setDailyReturnTableData(returnsCombined);
    // setDetailedOrdersLoading(false);
    setAllOrders(allOrdersData);
    setCalculating(false)
};


  useEffect(() => {
   getMinerData()
   getBtcPrices()
   getEthPrices()
  }, []);


  return (
    <DailyReturnsContext.Provider
      value={{
        slippagePercentage,setSlippagePercentage,
        tradeFeesPercentage,setTradeFeesPercentage,
        portfolioInput,setPortfolioInput,
        multiplierInput,setMultiplierInput,
        slippageInput,setSlippageInput,
        tradeFeesInput,setTradeFeesInput,
        portfolioValueAssumption,setPortfolioValueAssumption,
        multiplierAssumption,setMultiplierAssumption,
        dailyReturnTableData,
        setDailyReturnTableData,
        addressSelected,
        setAddressSelected,
        positionsData,
        setPositionsData,
        getMinerData,
        btcPriceList,
        ethPriceList,
        setValue,
        value,
        tabState,
        setTabState,
        detailedOrdersTableData, setDetailedOrdersTableData,
        ethOrders,setEthOrders,
        allOrders,setAllOrders,
        detailedOrdersTableDataSetter,
        calculating,setCalculating,
        getDetailedOrders,
        getEthDetailedOrders,
        getAllOrdersData,
        setStatData,
        statData,
        pieChartData, setPieChartDataData,
        positionsForOrders,
        positionsForPerformance,
        showSidebar,
        setShowSidebar
      }}
    >
      {children}
    </DailyReturnsContext.Provider>
  );
};

export default DailyReturnsProvider;
